<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.newMaintenanceBatches')"
    id="newMaintenanceBatches"
  >
    <div id="outer-title">{{ $t("i18n.newMaintenanceBatches") }}</div>
    <div id="width" style="width: 60%">
      <jl-form
        :columns="columns"
        @onSubmit="onSubmit"
        @resetForm="onCancel"
      ></jl-form>
    </div>
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="addEleOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="handleSelectionChange"
        height="500"
      ></jl-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="newElevatorVisible = false">
            {{ $t("i18n.cancel") }}
          </el-button>
          <el-button type="primary" @click="handleGenerateBatch">
            {{ $t("i18n.generateBatch") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "PlanBathAdd",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "startTime",
          label: "startTime",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "maintenanceManId",
          label: "maintenancePerson",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "days",
          label: "howManyDaysToComplete",
          placeholder: t("i18n.howManyDayVerify"),
          type: "input",
          rules: [
            { required: true, message: t("i18n.input") },
            {
              pattern: /^\+?[1-5]\d*$/,
              message: t("i18n.howManyDayVerify"),
              trigger: "blur",
            },
          ],
        },
        {
          prop: "daysEd",
          label: "theNumberOfTimesToDoNextHalfMonth",
          placeholder: t("i18n.theNumberOfTimesVerify"),
          type: "input",
          rules: [
            { required: true, message: t("i18n.input") },
            {
              pattern: /^([1-9]|1\d|2[0-4])$/,
              message: t("i18n.theNumberOfTimesVerify"),
              trigger: "blur",
            },
          ],
        },
      ],
      newElevatorVisible: false,
      addEleData: [],
      addEleTotal: 0,
      addEleOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      addEleColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 300,
          type: "input",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      form: {},
    });

    const getMainPerson = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.columns.map((item) => {
        if (item.prop === "maintenanceManId") {
          item.data = data;
        }
      });
    };
    getMainPerson();

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      params.id = route.query.id;
      const { data } = await proxy.$api.maintenance.getMainElevator(params);
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const handleSelectionChange = (val) => {
      let arr = [];
      val.map((item) => {
        arr.push(item.id);
      });
      state.form.elevatorStr = arr.join(",");
    };

    const onSubmit = (row) => {
      state.newElevatorVisible = true;
      for (let key in row) {
        state.form[key] =
          row[key] instanceof Date ? proxy.$defined.format(row[key]) : row[key];
      }
    };

    const handleGenerateBatch = async () => {
      let id = route.query.id;
      if (state.form.elevatorStr) {
        await proxy.$api.maintenance.addPlanBath(id, state.form);
        proxy.$defined.tip(
          t("i18n.newMaintenanceBatches") + t("i18n.success"),
          "success"
        );
        setTimeout(() => {
          router.go(-1);
        }, 500);
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
      }
    };

    const onCancel = () => {
      router.go(-1);
    };

    return {
      ...toRefs(state),
      onSubmit,
      getAddEleList,
      handleSelectionChange,
      handleGenerateBatch,
      onCancel,
    };
  },
};
</script>

<style lang="scss">
#newMaintenanceBatches {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #newMaintenanceBatches {
    #width {
      width: 100% !important;
    }
  }
}
</style>
